import "./src/styles/global.css";

export function onRouteUpdate(location, prevLocation) {
  const placeholder_event = () => {};

  // Form
  const agree = document.querySelector("#agree");
  const formSubmit = document.querySelector("#formsubmit");

  const agreeClickEvent = () => {
    const agCheck = agree.hasAttribute("checked");
    if (agCheck === false) {
      agree.setAttribute("checked", "");
      formSubmit.removeAttribute("disabled");
    } else {
      agree.removeAttribute("checked");
      formSubmit.setAttribute("disabled", "");
    }
  };

  if (formSubmit !== null) {
    formSubmit.setAttribute("disabled", "");
  }

  if (agree !== null) {
    agree.onclick = placeholder_event;
    agree.onclick = agreeClickEvent;
  }

  // navメニュー
  const navButton = document.querySelector("#nav-menu-button");
  const gnav = document.querySelector("#gnav");
  const closeBack = document.querySelector("#close-back");

  const nav_click_event = () => {
    const navCheck = gnav.hasAttribute("class", "opened");
    if (navCheck === false) {
      gnav.setAttribute("class", "opened");
      navButton.setAttribute("class", "opened");
    } else {
      gnav.removeAttribute("class", "opened");
      navButton.removeAttribute("class", "opened");
    }
  };

  if (navButton !== null) {
    navButton.onclick = placeholder_event;
    navButton.onclick = nav_click_event;
  }

  if (closeBack !== null) {
    closeBack.onclick = placeholder_event;
    closeBack.onclick = nav_click_event;
  }

  //スクロール　ヘッダー位置調整
  const headObi = document.querySelector("#id-header");
  if (headObi !== null) {
    window.addEventListener("scroll", () => {
      const hdCheck = headObi.hasAttribute("class", "toplock");
      const wHeight = window.innerHeight;
      const wTopScroll = window.scrollY;
      if (wTopScroll > 30) {
        headObi.setAttribute("class", "toplock");
      } else {
        headObi.removeAttribute("class", "toplock");
      }
    });
  }

  //Topスクロールボタンの表示非表示
  const topButton = document.querySelector("#top-button-box");
  if (topButton !== null) {
    window.addEventListener("scroll", () => {
      const tbCheck = topButton.hasAttribute("class", "view-on");
      const winHeight = window.innerHeight;
      const winTopScroll = window.scrollY;
      if (winTopScroll > winHeight) {
        topButton.setAttribute("class", "view-on");
      } else {
        topButton.removeAttribute("class", "view-on");
      }
    });
  }

  //Topスクロール　「#」を検出して移動
  const path = location.location.pathname;
  const hash = location.location.hash;
  if (path === "/" && hash !== "") {
    // scroll to #
    window.location.href = hash;
  }
}
