exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-v-2-index-js": () => import("./../../../src/pages/about_v2/index.js" /* webpackChunkName: "component---src-pages-about-v-2-index-js" */),
  "component---src-pages-about-v-2-policy-index-js": () => import("./../../../src/pages/about_v2/policy/index.js" /* webpackChunkName: "component---src-pages-about-v-2-policy-index-js" */),
  "component---src-pages-about-v-2-policyif-index-js": () => import("./../../../src/pages/about_v2/policyif/index.js" /* webpackChunkName: "component---src-pages-about-v-2-policyif-index-js" */),
  "component---src-pages-about-v-2-staff-index-js": () => import("./../../../src/pages/about_v2/staff/index.js" /* webpackChunkName: "component---src-pages-about-v-2-staff-index-js" */),
  "component---src-pages-about-v-2-terms-conditions-index-js": () => import("./../../../src/pages/about_v2/terms-conditions/index.js" /* webpackChunkName: "component---src-pages-about-v-2-terms-conditions-index-js" */),
  "component---src-pages-about-v-2-terms-index-js": () => import("./../../../src/pages/about_v2/terms/index.js" /* webpackChunkName: "component---src-pages-about-v-2-terms-index-js" */),
  "component---src-pages-commerce-index-js": () => import("./../../../src/pages/commerce/index.js" /* webpackChunkName: "component---src-pages-commerce-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-index-js": () => import("./../../../src/pages/policy/index.js" /* webpackChunkName: "component---src-pages-policy-index-js" */),
  "component---src-pages-policyif-index-js": () => import("./../../../src/pages/policyif/index.js" /* webpackChunkName: "component---src-pages-policyif-index-js" */),
  "component---src-pages-staff-index-js": () => import("./../../../src/pages/staff/index.js" /* webpackChunkName: "component---src-pages-staff-index-js" */),
  "component---src-pages-supported-devices-index-js": () => import("./../../../src/pages/supported-devices/index.js" /* webpackChunkName: "component---src-pages-supported-devices-index-js" */),
  "component---src-pages-terms-conditions-index-js": () => import("./../../../src/pages/terms-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-conditions-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */)
}

